<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1250" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{'关闭'}}</a-button>
      </template>
      <div class="menu-modal-container">
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
          <span slot="work_time" slot-scope="value">
          <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
            <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-tooltip>
        </span>
          <span slot="order_exception" slot-scope="value">
            <a-tag :color="orderExceptionColors[value]||''">{{orderException[value]||''}}</a-tag>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </a-modal>
    <detail-modal :visible.sync="detailModalVisible" :detail-data="detailModalDetailData"></detail-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import pagination from "@/mixins/pagination";
import {getWorkOrderListByCondition} from "A/businessmanagement";
import DetailModal from "V/businessManagement/environment/workOrder/DetailModal";
import {orderException, orderExceptionColors} from "@/json/maintenance";
export default {
  mixins: [areaselect, deptselect,pagination],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: ''
    },
    detailData: {
      default: null
    }
  },
  components:{
    DetailModal,
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '580px',
        overflowY: 'auto',
      },
      orderException,
      orderExceptionColors,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        work_type:'',
        work_child_type:'',
        work_person:'',
        work_address:'',
        work_status:'',
        monitor_num:'',
        order_exception:'',
        year:'',
        starttime:'',
        endtime:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '作业类型',
          dataIndex: 'work_type',
          key: 'work_type',
          ellipsis: true,
        },
        {
          title: '作业子类型',
          dataIndex: 'work_child_type',
          key: 'work_child_type',
          ellipsis: true,
        },
        {
          title: '作业点名称',
          dataIndex: 'point_name',
          key: 'point_name',
          ellipsis: true,
        },
        {
          title: '作业单位',
          dataIndex: 'unit_name',
          key: 'unit_name',
          ellipsis: true,
        },
        {
          title: '数量',
          dataIndex: 'num',
          key: 'num',
          ellipsis: true,
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key: 'unit',
          ellipsis: true,
        },
        {
          title: '作业人员',
          dataIndex: 'work_person',
          key: 'work_person',
          ellipsis: true,
        },
        {
          title: '作业时间',
          dataIndex: 'work_time',
          key: 'work_time',
          ellipsis: true,
          scopedSlots: { customRender: 'work_time' },
        },
        {
          title: '打点位置',
          dataIndex: 'work_address',
          key: 'work_address',
          ellipsis: true,
          scopedSlots: { customRender: 'work_address' },
        },
        {
          title: '摄像机编号',
          dataIndex: 'monitor_num',
          key: 'monitor_num',
          ellipsis: true,
        },
        {
          title: '作业时间状态',
          dataIndex: 'order_exception',
          key: 'order_exception',
          ellipsis: true,
          scopedSlots: { customRender: 'order_exception' },
        },
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   key: 'operation',
        //   ellipsis: true,
        //   align:'center',
        //   scopedSlots: { customRender: 'operation' },
        //   width: 70
        // },
      ],
      modalVisible: false,
      monitorpointList:[],
      detailModalVisible: false,
      detailModalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '作业任务详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.initDeptOptionsAll();
  },
  methods: {

    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
      this.queryInstallDate = []
    },
    initDetail() {
      if (this.detailData) {
        this.$nextTick(() => {
          this.tableLoading = true;
          let params = {
            monitorpointnum: this.detailData.monitorpointnum,
            location: this.detailData.location,
            work_type: "清洁",
            work_child_type: "垃圾处理",
            garbage_type: this.detailData.garbage_type1,
            clear_type: this.detailData.clear_type1,
            work_time: this.detailData.work_time,
            starttime: this.detailData.starttime,
            endtime: this.detailData.endtime,
          }
          getWorkOrderListByCondition(params).then(res => {
            if (res && res.returncode == '0') {
              this.tableLoading = false;
              this.tableData = res.item;
              this.pagination.total = res.count;
            }
          }).catch(err => {
            this.tableLoading = false;
          })
        })
      }
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.detailModalDetailData=record
            this.detailModalVisible=true
          },
        },
      }
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>