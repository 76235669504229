<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 20%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="垃圾分类" prop="garbage_type" style="width: 20%">
              <a-select v-model="queryParams.garbage_type">
                <a-select-option value="生活垃圾">生活垃圾</a-select-option>
                <a-select-option value="装修垃圾">装修垃圾</a-select-option>
                <a-select-option value="干垃圾">干垃圾</a-select-option>
                <a-select-option value="湿垃圾">湿垃圾</a-select-option>
                <a-select-option value="易腐垃圾">易腐垃圾</a-select-option>
                <a-select-option value="可回收垃圾">可回收垃圾</a-select-option>
                <a-select-option value="有毒有害垃圾">有毒有害垃圾</a-select-option>
                <a-select-option value="其他垃圾">其他垃圾</a-select-option>
                <a-select-option value="无">无</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="清运类型" prop="clear_type" style="width: 20%">
              <a-select v-model="queryParams.clear_type">
                <a-select-option value="收集">收集</a-select-option>
                <a-select-option value="清运">清运</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 20%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
            </div>
            <div v-show="showAdvanced" class="advanced-query">
<!--              <a-form-model-item label="作业点名称" prop="point_name" style="width: 20%">-->
<!--                <a-input v-model="queryParams.point_name"></a-input>-->
<!--              </a-form-model-item>-->
<!--              <a-form-model-item label="作业单位" prop="unit_name" style="width: 20%">-->
<!--                <a-input v-model="queryParams.unit_name"></a-input>-->
<!--              </a-form-model-item>-->
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button v-if="btnList.includes('导出')" @click="exportModal(0)" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
<!--            <a-button v-if="btnList.includes('新增')" @click="addRecord()" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :customRow="customRow" :columns="tableColumns" :data-source="tableData" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="create_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="count" slot-scope="value, record"><a-button style="margin-left: -15px" type="link" @click="showModal('detail',record)">{{value?Number(value):''}}</a-button></span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail'">详情</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import addOrEditModal from "V/businessManagement/environment/garbageClearance/addOrEditModal";
import {exportGarbageClearStatisticsExcel, getGarbageClearStatisticsList} from "A/businessmanagement";
export default {
  name: "GarbageStatistics",
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        garbage_type:'',
        clear_type:'',
        point_name:'',
        unit_name:'',
        userdepsid:'',
        work_time:null,
        starttime:'',
        endtime:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '作业点名称',
          dataIndex: 'garbage_location',
          key: 'garbage_location',
          ellipsis: true,
          width:150,
        },
        {
          title: '作业单位',
          dataIndex: 'unit_name',
          key: 'unit_name',
          ellipsis: true,
        },
        {
          title: '数量',
          dataIndex: 'count',
          key: 'count',
          ellipsis: true,
          scopedSlots: { customRender: 'count' },
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key: 'unit',
          ellipsis: true,
        },
        {
          title: '区域',
          dataIndex: 'region',
          key: 'region',
          ellipsis: true,
        },
        {
          title: '楼层',
          dataIndex: 'floor',
          key: 'floor',
          ellipsis: true,
        },
        {
          title: '垃圾分类',
          dataIndex: 'garbage_type',
          key: 'garbage_type',
          ellipsis: true,
        },
        {
          title: '清运类型',
          dataIndex: 'clear_type',
          key: 'clear_type',
          ellipsis: true,
        },
        {
          title: '作业内容',
          dataIndex: 'content',
          key: 'content',
          ellipsis: true,
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   key: 'remark',
        //   ellipsis: true,
        // },
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   key: 'operation',
        //   ellipsis: true,
        //   align:'center',
        //   scopedSlots: { customRender: 'operation' },
        //   width: 70
        // },
      ],
      userdepidCascaderSelected: [],
      queryInstallDate:null,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      addModalVisible: false,
      addModalDetailData: null,

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "垃圾清运统计"
    },
    breadcrumb() {
      const pages = [{name:"业务管理",path:""}]
      pages.push({name:"环境管理",path:""})
      pages.push({name:"垃圾处理",path:""})
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.initDeptOptionsAll();
    this.getTableData()
  },
  methods:{
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getGarbageClearStatisticsList(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.queryParams.userdepsid=''
      this.queryInstallDate = []
      this.$refs.queryForm.resetFields();
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let garbage_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'garbage_id', garbage_id);
      this.showModal(type,record)
    },
    showModal(type,record){
      if(type=='detail') {
        this.modalDetailData = record
        this.modalDetailData.clear_type1=this.queryParams.clear_type?this.queryParams.clear_type:""
        this.modalDetailData.garbage_type1=this.queryParams.garbage_type?this.queryParams.garbage_type:""
        this.modalDetailData.starttime=this.queryParams.starttime?this.queryParams.starttime:""
        this.modalDetailData.endtime=this.queryParams.endtime?this.queryParams.endtime:""
        this.modalShowType = type
        this.modalVisible = true
      }
    },
    exportModal(task_type){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportGarbageClearStatistics(task_type)
      }).catch(()=>{
      });
    },
    exportGarbageClearStatistics(task_type){
      let params = {
        ...this.queryParams,
        task_type:task_type,
      }
      this.showLoading();
      exportGarbageClearStatisticsExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData=record
            this.modalShowType="detail"
            this.modalVisible=true
          },
        },
      }
    },
  },
}
</script>
<style scoped>

</style>